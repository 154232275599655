import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomModalService } from '../../../../components/custom-modal';
import { CountryService } from '../../country/country.service';
import { StateService } from '../../state/state.service';
import { AddressDetailsService } from './address-details.service';
import * as alertify from 'alertifyjs';
import { CityService } from '../../city/city.service';
import _ from 'underscore';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service';
import { ToastrService } from 'ngx-toastr';
import { SubscriberDetailsComponent } from '../subscriber-details.component';
import { TokenStorageService } from '../../../../auth/token-storage-service';
import { PrimaryInformationComponent } from '../../add-subscriber-details/primary-information/primary-information.component';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent implements OnInit {

  @Input() custId: any;
  @Input() ImportFrm: string;
  @ViewChild(SubscriberDetailsComponent, { static: false })
  private subscriberComponent: SubscriberDetailsComponent;
  @ViewChild(PrimaryInformationComponent, { static: false })
  private primaryInformationComponent: PrimaryInformationComponent;

  searchPincodeLink: string = '';
  default_pincode_value: string = '';
  parentpin: any;
  typeOfPropertyList: any;
  // remraks:any = "";
  insertAddressDetailsForm = new FormGroup({
    addressType: new FormControl(null,Validators.required),
    propertyType: new FormControl(null),
    address: new FormControl('', [Validators.required]),
    landmark: new FormControl(''),
    area: new FormControl(null),
    pincode: new FormControl('', [Validators.required]),
    pincodeId: new FormControl(null, [Validators.required]),
    Country: new FormControl('', [Validators.required]),
    countryId: new FormControl(null),
    City: new FormControl('', [Validators.required]),
    cityId: new FormControl(null),
    State: new FormControl('', [Validators.required]),
    stateId: new FormControl(null),
    remarks: new FormControl('', [Validators.required]),
    Taluka: new FormControl(null),
    talukaId: new FormControl(null),
    District: new FormControl(null),
    districtId: new FormControl(null),
    Constituency: new FormControl(null),
    constituencyId: new FormControl(null),
    building: new FormControl(null),
    typeOfProperty: new FormControl(null),
    block: new FormControl(null,)
  });

  addressMapArray: any;
  addressMapArray2: any;
  addressMapArray3: any;
  buildingList: any;
  tempDataList: any;
  public areaAllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public pincodeAllList: any[] = [
    {
      pincode: '',
      pincodeid: '',
    },
  ];
  public countryList: any[] = [{
    country_name: '',
    country_id: ''
  }];
  public stateList: any[] = [{
    state_name: '',
    state_id: ''
  }];
  addressTypes: any[] = [
    { text: '', value: '' }
  ]
  selectForUpdate: any;
  allAddressDetailsData: any;
  addressDetailsData: any;
  propertyTypeList: any;
  presentpincode: any;
  paymentpincode: any;
  permanantpincode: any;
  constructor(
    private customModalService: CustomModalService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private addressDetailsService: AddressDetailsService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private tosterservice: ToastrService,
    private tokenservice: TokenStorageService,
    private commonListService: CommonListServicesService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // console.log("came from subs==.",this.ImportFrm);
    this.addressDetailsService.getAddressDetails(this.custId, this.ImportFrm).subscribe(result => {
      if (result.responseCode !== 200 && result.responseMessage) {
        this.tosterservice.error(result.responseMessage);
      } else {
        this.allAddressDetailsData = result.data;

        this.addressDetailsData = result.data.present;
        this.addressTypes = [
          {
            text: 'present',
            value: 'present'
          },
          {
            text: 'permanent',
            value: 'permanent'
          },
          {
            text: 'payment',
            value: 'payment'
          }
        ];
        this.addressDetailsData.addressType = 'present';
        this.presentpincode = this.addressDetailsData.pincodeId
        this.addressDetailsData.address = this.addressDetailsData.address1;
        this.setUpdateValueInForm(this.addressDetailsData);
        //this.addressTypes = result.data.addressTypes;
        //this.getCountry(this.addressDetailsData.country, this.addressDetailsData.state, this.addressDetailsData.city);
        this.getPincodeAllAndSetValue(result.data.present);
      }
    });
    this.getAddressMapping();
    this.getPincode();
    this.getcommonList();
    this.getPropertyType();
  }

  getPropertyType() {
    this.commonListService.getCommonListtypeOfProperty('typeOfProperty').subscribe(res => {
      this.typeOfPropertyList = res.dataList
    })
  }
  getcommonList() {
    this.addSubscriberDetailsService.getCommonListPropertyType().subscribe((res) => {
      this.propertyTypeList = res.dataList;
    })
  }
  onPropertyTypeChange(event) {
    this.insertAddressDetailsForm.get('propertyType').setValue(event.text);
    this.insertAddressDetailsForm.updateValueAndValidity();
  }
  getAddressMapping() {
    let temp = this.tokenservice.getAddressLevel()
    this.addressMapArray = temp.filter(res => res.name.toLowerCase() != 'pincode' && res.name.toLowerCase() != 'area' && res.name.toLowerCase() != 'building')
    this.addressMapArray2 = temp.filter(res => (res.name.toLowerCase() == 'area'))
    this.addressMapArray3 = temp.filter(res => (res.name.toLowerCase() == 'building'))
    let tempn = temp.filter(res => (res.name.toLowerCase() == 'pincode'))
    this.parentpin = tempn[0].levelNo
    if (this.addressMapArray.find(res => res.name.toLowerCase() == 'district')) {
      this.insertAddressDetailsForm.get('District').setValidators([Validators.required]);
      this.insertAddressDetailsForm.get('District').updateValueAndValidity();
    }
    if (this.addressMapArray.find(res => res.name.toLowerCase() == 'taluka')) {
      this.insertAddressDetailsForm.get('Taluka').setValidators([Validators.required]);
      this.insertAddressDetailsForm.get('Taluka').updateValueAndValidity();
    }
    if (this.addressMapArray.find(res => res.name.toLowerCase() == 'constituency')) {
      this.insertAddressDetailsForm.get('Constituency').setValidators([Validators.required]);
      this.insertAddressDetailsForm.get('Constituency').updateValueAndValidity();
    }
    // console.log('-->',this.addressMapArray)
  }

  getPincode() {
    this.searchPincodeLink = 'address/searchNew?id=' + this.parentpin + '&s=';
  }

  // getAreaAll() {
  //   this.addSubscriberDetailsService.getAreaAll().subscribe(result => {
  //     this.areaAllList = result.dataList;
  //   });
  // }

  // getPincodeAll() {
  //   this.addSubscriberDetailsService.getPincodeAll().subscribe(result => {
  //     this.pincodeAllList = result.dataList;
  //   });
  // }

  getPincodeAllAndSetValue(dataList?: any, area?: any) {
    this.default_pincode_value=null
    this.tempDataList = dataList;
    this.default_pincode_value = dataList.pincode;
  }
  onPincodeChange(elItem) {
    if(!elItem){
      this.insertAddressDetailsForm.get('area').reset();
      this.insertAddressDetailsForm.get('building').reset();
      this.insertAddressDetailsForm.get('typeOfProperty').reset();
    }
    

    let el;
    let call_from_autocomplete;
    if (_.isArray(elItem.event) == true) {
      console.log(elItem.event);
      elItem.event.forEach((item) => {
        if (item && item.addressDetails && item.addressDetails.pincodeId && (this.presentpincode === item.addressDetails.pincodeId || this.paymentpincode === item.addressDetails.pincodeId || this.permanantpincode === item.addressDetails.pincodeId)) {
          el = item;
          return
        }
      });
      ;
    } else {
      el = elItem.event;
      call_from_autocomplete = elItem.call_from_autocomplete;
    }
    if (el && el.name) {
      this.insertAddressDetailsForm.controls.pincode.setValue(el.addressDetails.pincodeId);
      this.insertAddressDetailsForm.controls.pincodeId.setValue(el.addressDetails.pincodeId);
      this.insertAddressDetailsForm.patchValue({ Country: el.addressDetails.country });
      this.insertAddressDetailsForm.patchValue({ State: el.addressDetails.state });
      this.insertAddressDetailsForm.patchValue({ City: el.addressDetails.city });
      this.insertAddressDetailsForm.patchValue({ District: el.addressDetails.district });
      this.insertAddressDetailsForm.patchValue({ Taluka: el.addressDetails.taluka });
      this.insertAddressDetailsForm.patchValue({ Constituency: el.addressDetails.constituency });
      this.insertAddressDetailsForm.patchValue({ countryId: el.addressDetails.countryId });
      this.insertAddressDetailsForm.patchValue({ stateId: el.addressDetails.stateId });
      this.insertAddressDetailsForm.patchValue({ cityId: el.addressDetails.cityId });
      this.insertAddressDetailsForm.patchValue({ districtId: el.addressDetails.districtId });
      this.insertAddressDetailsForm.patchValue({ talukaId: el.addressDetails.talukaId });
      this.insertAddressDetailsForm.patchValue({ building: el.addressDetails.building });
      this.insertAddressDetailsForm.patchValue({ constituencyId: el.addressDetails.constituencyId });
      //this.insertAddressDetailsForm.patchValue({ block: el.addressDetails.blockNo});
      this.addSubscriberDetailsService.getDetailsByPincode(el.parentId).subscribe(result => {
        this.areaAllList = result.dataList;
        // this.getPincodeAllAndSetValue()

        // let data = result.data;

        if (this.tempDataList) {
          let area: any;

          area = this.areaAllList.find(res => res.id ==this.tempDataList.area);

          if (area) {
            this.insertAddressDetailsForm.patchValue({ area: area.id });
              
            let typeOfProperty: any;
            typeOfProperty = this.typeOfPropertyList.find(res => res.value ==this.tempDataList.typeOfProperty);

            if (typeOfProperty) {
              this.insertAddressDetailsForm.patchValue({ typeOfProperty: typeOfProperty.value });

              this.addSubscriberDetailsService.getDetailsByProperty(area.id, typeOfProperty.value).subscribe(res => {
                let buildingList = res.dataList;
                this.buildingList = res.dataList;

                if (buildingList && buildingList.length > 0) {
                  let building: any = buildingList.find(res => res.id == this.tempDataList.building);

                  if (building) {
                    this.insertAddressDetailsForm.patchValue({ building: building.id });
                  }
                }
              });
            }
          }
        } else {
          this.insertAddressDetailsForm.patchValue({ area: null });
          this.insertAddressDetailsForm.patchValue({ typeOfProperty: null });
          this.insertAddressDetailsForm.patchValue({ building: null });
        }

      });
    }
    else if (el == null) {
      this.insertAddressDetailsForm.get('pincode').reset();
      this.insertAddressDetailsForm.get('pincodeId').reset();
      this.insertAddressDetailsForm.get('Country').reset();
      this.insertAddressDetailsForm.get('State').reset();
      this.insertAddressDetailsForm.get('City').reset();
      this.insertAddressDetailsForm.get('District').reset();
      this.insertAddressDetailsForm.get('Taluka').reset();
      this.insertAddressDetailsForm.get('Constituency').reset();
      this.insertAddressDetailsForm.get('area').reset();
      this.insertAddressDetailsForm.get('building').reset();
      this.insertAddressDetailsForm.get('typeOfProperty').reset();
      this.insertAddressDetailsForm.get('typeOfProperty').updateValueAndValidity();
      this.areaAllList = [];
      this.typeOfPropertyList = [];
      this.buildingList = [];
    }
  }

  onChangeArea(event) {
    this.typeOfPropertyList = [];
    this.buildingList = [];
    this.insertAddressDetailsForm.get('typeOfProperty').clearValidators();
    this.insertAddressDetailsForm.get('typeOfProperty').reset();
    this.insertAddressDetailsForm.get('typeOfProperty').updateValueAndValidity();
    this.insertAddressDetailsForm.get('building').reset();
    this.insertAddressDetailsForm.get('building').updateValueAndValidity();
    if(event && event.id){
      this.insertAddressDetailsForm.get('typeOfProperty').setValidators([Validators.required]);
      this.insertAddressDetailsForm.get('typeOfProperty').updateValueAndValidity();
      this.getPropertyType();
    }
  }

  onChangePropertyTypeBuilding(event) {
    this.insertAddressDetailsForm.get('building').reset();
    this.insertAddressDetailsForm.get('building').updateValueAndValidity();
    let area = this.insertAddressDetailsForm.get('area').value;
    this.buildingList = []
    if (event && event.value) {
      this.addSubscriberDetailsService.getDetailsByProperty(area, event.value).subscribe(res => {
        this.buildingList = res.dataList
      })
    }
  }

  setUpdateValueInForm(addressDetails_data) {
    this.selectForUpdate = addressDetails_data;
    this.insertAddressDetailsForm.patchValue(addressDetails_data);
    this.insertAddressDetailsForm.patchValue({ block: addressDetails_data.blockNo });
    this.insertAddressDetailsForm.patchValue({ building: addressDetails_data.building });
  }

  onAddressTypeChange($event: any) {
    this.default_pincode_value=null
    this.insertAddressDetailsForm.controls.pincode.reset();
    this.insertAddressDetailsForm.controls.pincodeId.reset();
    this.paymentpincode = null
    this.presentpincode = null
    this.permanantpincode = null
    if ($event) {
      let addressTypeData = this.allAddressDetailsData[$event];
      if ($event == 'present') {
        this.presentpincode = addressTypeData ? addressTypeData.pincodeId : null;
      }
      if ($event == 'payment') {
        this.paymentpincode = addressTypeData ? addressTypeData.pincodeId : null;
      }
      if ($event == 'permanent') {
        this.permanantpincode = addressTypeData ? addressTypeData.pincodeId : null;
      }
      if (addressTypeData) {
        addressTypeData.addressType = $event;
        addressTypeData.address = addressTypeData.address1;
        this.insertAddressDetailsForm.controls.pincode.setValue(addressTypeData.pincode);
        this.insertAddressDetailsForm.controls.pincodeId.setValue(addressTypeData.pincodeId);
        this.insertAddressDetailsForm.patchValue({ Country: addressTypeData.country});
        this.insertAddressDetailsForm.patchValue({ State: addressTypeData.state});
        this.insertAddressDetailsForm.patchValue({ City: addressTypeData.city});
        this.insertAddressDetailsForm.patchValue({ District: addressTypeData.district});
        this.insertAddressDetailsForm.patchValue({ Taluka: addressTypeData.taluka});
        this.insertAddressDetailsForm.patchValue({ Constituency: addressTypeData.constituency});
        this.insertAddressDetailsForm.patchValue({ block: addressTypeData.blockNo});
        this.insertAddressDetailsForm.patchValue({ address: addressTypeData.address});
        this.insertAddressDetailsForm.patchValue({ landmark: addressTypeData.landmark});
        this.default_pincode_value=addressTypeData.pincode
        this.addSubscriberDetailsService.getDetailsByPincode(addressTypeData.pincodeId).subscribe(result => {
          this.areaAllList = result.dataList;
          if (this.tempDataList) {
            let area: any;
  
            area = this.areaAllList.find(res => res.id == addressTypeData.area);
  
            if (area) {
              this.insertAddressDetailsForm.patchValue({ area: area.id });
  
              let typeOfProperty: any;
              typeOfProperty = this.typeOfPropertyList.find(res => res.value == addressTypeData.typeOfProperty);
  
              if (typeOfProperty) {
                this.insertAddressDetailsForm.patchValue({ typeOfProperty: typeOfProperty.value });
  
                this.addSubscriberDetailsService.getDetailsByProperty(area.id, typeOfProperty.value).subscribe(res => {
                  let buildingList = res.dataList;
                  this.buildingList = res.dataList;
  
                  if (buildingList && buildingList.length > 0) {
                    let building: any = buildingList.find(res => res.id ==addressTypeData.building);
  
                    if (building) {
                      this.insertAddressDetailsForm.patchValue({ building: building.id });
                    }
                  }
                });
              }
            }
          } else {
            this.insertAddressDetailsForm.patchValue({ area: null });
            this.insertAddressDetailsForm.patchValue({ typeOfProperty: null });
            this.insertAddressDetailsForm.patchValue({ building: null });
          }
  
        });
      }
      else {
        this.insertAddressDetailsForm.get('propertyType').reset();
        this.insertAddressDetailsForm.get('address').reset();
        this.insertAddressDetailsForm.get('landmark').reset();
        this.insertAddressDetailsForm.get('area').reset();
        this.insertAddressDetailsForm.get('pincode').reset();
        this.insertAddressDetailsForm.get('pincodeId').reset();
        this.insertAddressDetailsForm.get('Country').reset();
        this.insertAddressDetailsForm.get('City').reset();
        this.insertAddressDetailsForm.get('State').reset();
        this.insertAddressDetailsForm.get('remarks').reset();
        this.insertAddressDetailsForm.get('Taluka').reset();
        this.insertAddressDetailsForm.get('District').reset();
        this.insertAddressDetailsForm.get('Constituency').reset();
        this.insertAddressDetailsForm.get('building').reset();
        this.insertAddressDetailsForm.get('typeOfProperty').reset();
        this.insertAddressDetailsForm.get('block').reset();
        this.default_pincode_value = ''
      }
    }
    // else {
    //   this.insertAddressDetailsForm.get('typeOfProperty').reset();
    //   this.insertAddressDetailsForm.get('propertyType').reset();
    //   this.insertAddressDetailsForm.get('address').reset();
    //   this.insertAddressDetailsForm.get('landmark').reset();
    //   this.insertAddressDetailsForm.get('area').reset();
    //   this.insertAddressDetailsForm.get('pincode').reset();
    //   this.insertAddressDetailsForm.get('pincodeId').reset();
    //   this.insertAddressDetailsForm.get('Country').reset();
    //   this.insertAddressDetailsForm.get('City').reset();
    //   this.insertAddressDetailsForm.get('State').reset();
    //   this.insertAddressDetailsForm.get('remarks').reset();
    //   this.insertAddressDetailsForm.get('Taluka').reset();
    //   this.insertAddressDetailsForm.get('District').reset();
    //   this.insertAddressDetailsForm.get('Constituency').reset();
    //   this.insertAddressDetailsForm.get('building').reset();
    //   this.insertAddressDetailsForm.get('typeOfProperty').reset();
    //   this.insertAddressDetailsForm.get('block').reset();
    // } 
    else {
      this.insertAddressDetailsForm.reset();
      this.typeOfPropertyList = [];
    }
  }


  public cityList: any[] = [{
    city_name: '',
    city_id: ''
  }];

  submitted: boolean;
  onClickSaveForm() {

    if (this.insertAddressDetailsForm.valid === true) {
      let self = this;
      alertify.confirm("Address Details", "Are You Sure You want to Change Address Details ?",
        function () {
          self.saveApiCall();
          //self.closeModal('address-details');

        },
        function () {

        });
    } else {
      this.submitted = true;
      this.tosterservice.error("please fill all the required fields");
      this.insertAddressDetailsForm.markAllAsTouched();
    }
  }

  /** Remove Save & Close Button:vijay*/
  // onClickSaveAndCloseForm() {

  //   if (this.insertAddressDetailsForm.valid === true) {
  //     let self = this;
  //     alertify.confirm("Address Details","Are You Sure You want to Change Address Details ?",
  //     function() {
  //       self.saveApiCall();
  //       self.closeModal('address-details');
  //     },
  //     function() {

  //     });
  //   } else {
  //     this.submitted = true;
  //     this.insertAddressDetailsForm.markAllAsTouched();
  //   }
  // }

  saveApiCall() {
    let apiInputData = Object.assign({}, this.insertAddressDetailsForm.value);
    apiInputData.custId = this.custId;
    let formatedData;
    if (this.ImportFrm == 'Subscriber') {
      formatedData = {
        address: {
          address1: apiInputData.address,
          landmark: apiInputData.landmark,
          areaId: apiInputData.area,
          pincodeId: apiInputData.pincodeId,
          countryId: apiInputData.countryId,
          cityId: apiInputData.cityId,
          stateId: apiInputData.stateId,
          constituencyId: apiInputData.constituencyId,
          talukaId: apiInputData.talukaId,
          districtId: apiInputData.districtId,
          buildingId: apiInputData.building,
          blockNo: apiInputData.block,
          typeOfProperty: apiInputData.typeOfProperty,
          propertyType: apiInputData.propertyType
        },
        addressType: apiInputData.addressType,
        remarks: apiInputData.remarks,
        custId: parseInt(apiInputData.custId)
      };
    }
    else if (this.ImportFrm == 'CustomerLead') {
      formatedData = {
        leadAddressDto: {
          address1: apiInputData.address,
          landmark: apiInputData.landmark,
          areaId: apiInputData.area,
          pincodeId: apiInputData.pincodeId,
          countryId: apiInputData.countryId,
          cityId: apiInputData.cityId,
          stateId: apiInputData.stateId,
          constituencyId: apiInputData.constituencyId,
          talukaId: apiInputData.talukaId,
          typeOfProperty: apiInputData.typeOfProperty,
          districtId: apiInputData.districtId,
          buildingId: apiInputData.building,
          blockNo: apiInputData.block,
          propertyType: apiInputData.propertyType
        },
        addressType: apiInputData.addressType,
        remarks: apiInputData.remarks,
        custLeadId: parseInt(apiInputData.custId)
      };
    }

    else if (this.ImportFrm == 'PartnerLead') {
      formatedData = {
        leadAddressDto: {
          address1: apiInputData.address,
          landmark: apiInputData.landmark,
          areaId: apiInputData.area,
          pincodeId: apiInputData.pincodeId,
          countryId: apiInputData.countryId,
          cityId: apiInputData.cityId,
          stateId: apiInputData.stateId,
          constituencyId: apiInputData.constituencyId,
          talukaId: apiInputData.talukaId,
          districtId: apiInputData.districtId,
          buildingId: apiInputData.building,
          blockNo: apiInputData.block,
          propertyType: apiInputData.propertyType
        },
        addressType: apiInputData.addressType,
        remarks: apiInputData.remarks,
        partnerLeadId: parseInt(apiInputData.custId)
      };
    }

    this.addressDetailsService.insertAddressDetails(formatedData, this.ImportFrm).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.addressDetailsService.getAddressDetails(this.custId, this.ImportFrm).subscribe(result => {
            this.allAddressDetailsData = result.data;
            this.addressDetailsData = result.data[formatedData.addressType];
            this.customModalService.close('address-details', result);  /**New change */
          });
          this.tosterservice.success('Address Details Save Successfully.');
          this.customModalService.close('address-details', result);
          this.insertAddressDetailsForm.patchValue({ 'remarks': null });
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }

}
