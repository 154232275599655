import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';


import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

// URL to web api
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class AddressDetailsService {
  constructor(private http: HttpClient) {}

  getAddressDetails(custId : any, ImportFrm:string): Observable<any> {
    let get_url : string;
    if(ImportFrm == 'Subscriber')
    {
      get_url = baseApiUrl + 'subscriber/getAddressDetails/' + custId;
    }
    else if(ImportFrm == 'CustomerLead')
    {
      get_url = baseApiUrl + 'lead/customer/operation/getAddressDetails/' + custId;
    }
    else if(ImportFrm == 'PartnerLead')
    {
      get_url = baseApiUrl + 'lead/partner/getAddressDetails/' + custId;
    }
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  insertAddressDetails(addressDeatils_data : any, ImportFrm:string) : Observable<any> {
    let post_url : string;
    if(ImportFrm == 'Subscriber')
    {
      post_url = baseApiUrl + 'subscriber/updateAddressDetails';
    }
    else if(ImportFrm == 'CustomerLead')
    {
      post_url = baseApiUrl + 'lead/customer/operation/updateAddressDetails';
    }
    else if(ImportFrm == 'PartnerLead')
    {
      post_url = baseApiUrl + 'lead/partner/updateAddressDetails';
    }

    return this.http.post<any>(post_url, addressDeatils_data, httpOptions)
      .pipe(map(res => res),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
