import { ChangeDetectorRef, Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PackagesService } from '../packages/packages.service';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
// import  { CustomModalService } from '../../../../../components/custom-modal';
import { CustomModalService } from '../../../components/custom-modal';
import { PlanGroup, PlanGroupService } from '../../../services/plan_group/plan_group.service';
import { PlanStatus, PlanStatusService } from '../../../services/plan_status/plan_status.service';
import { PlanCategory, PlanCategoryService } from '../../../services/plan_category/plan_category.service';
import { PlanType, PlanTypeService } from '../../../services/plan_type/plan_type.service';
import { TaxService } from '../tax/tax.service';
import { ServicesService } from '../service/service.service';
import { PoliciesService } from '../../radius/policies/policies.service';
import { QuotaTypeTimeService } from '../../../services/quotaTypeTime/quotaTypeTime.service';
import { QuotaTypeDataService } from '../../../services/quotaTypeData/quotaTypeData.service';
import { QuotaTypeService } from '../../../services/quotaType/quotaType.service';
import { PolicyModel } from '../model/policy-model';
import { QosPolicyService } from '../policy/qos-policy.service';
import { DatePipe } from '@angular/common';
import { GenericTypeService } from '../../../services/common_list_generic/generic.service';
import { from, Subscription } from 'rxjs';
import { debug, timeStamp } from 'console';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogPassData{
  id:number;
}
import { collapseTextChangeRangesAcrossMultipleVersions, createStatement } from 'typescript';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../services/ibase-service';
import { NPackagesService } from '../packages/Npackages.service';
import { PackageModel } from '../packages/package-model';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { PaginationData } from '../../../core/modal/pagination-data';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { vasselection } from './vas-selection.modal';
import { LoginService } from '../../login/login.service';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { ResponseCode } from '../../../core/constant/response-code';
declare let $;
@Component({
  selector: 'app-add-packages',
  templateUrl: './add-packages.component.html',
  styleUrls: ['./add-packages.component.scss']
})
export class AddPackagesComponent extends AbstractBaseAddUpdateComponent<PackageModel> implements OnInit {

  @ViewChild('statusWrapper', { static: false }) statusWrapperRef: TemplateRef<any>;
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;

  public entity: PackageModel = new PackageModel();
  offerpriceInclTax: string;
  minmaxValueInvalid:boolean = false;
  tokenPriceInvalid:boolean = false;
  url: string;
  saveBtn: boolean = false;
  service_name: any;
  public sub: any = null;
  public packages_id: any = null;
  show_update: boolean = false;
  policies = true;
  unlimited_show: boolean;
  openQosPopup: boolean;
  qosPolicyflag: boolean = true;
  selectForUpdate: any;
  qos_policy_id: any = null;
  selected_package_type: any;
  error_message = "This field is required.";
  error = "required";
  submitted: boolean = false;
  param1: string;
  param2: string;
  isupdate: boolean = false;
  qosList1: any;
  isChecked: boolean = false;
  param3: string;
  planGroup: any;
  quotaDataFlag: boolean;
  quotaTimeFlag: boolean;
  timePlanDisable: boolean = true;
  dataPlanDisable: boolean = true;
  didPlanDisable: boolean;
  intercomePlanDisable: boolean;
  fupDataFlag: boolean = false;
  disabledFup: boolean = false;
  IsincludingTax: boolean = false;
  selectedVoice;
  NEXG_VAS :any;
  VAS_OTT:any;
  VAS_IPTV:any;
  isDisabled : boolean;
  isDisabledQcheck:boolean;
  incTaxAmount:number;
  fup:boolean;
  unlimited:boolean;
  timeQuotaUnlimited:boolean;
  isDisabled_audit:boolean=false;
  VASSelectedListFromAcrd: any[] = [];
  //--------------------------

  allColumns: any[] = [];
  neoGridSettings: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  tableKeyEnum = TableKeyEnum;
  dataSource = new MatTableDataSource([]);
  public paginationData = new PaginationData();
  private ngbRef: NgbModalRef;
  vas: vasselection  = new vasselection();
  vasSelectionList: any[] = [];
  AllVASList: any[] = [];
  VOICE_LIST: any[] = [];
  OTT_LIST: any[] = [];
  IPTV_LIST: any[] = [];
  public loginService: LoginService;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  isCheckedDisplayUnlimited: boolean = false;
  isCheckedAllowCustomer:boolean = false;
 
  insertPackagesForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    displayName: new FormControl('', [Validators.required]),
    code: new FormControl(''),
    saccode: new FormControl('', [Validators.required]),
    offerprice: new FormControl('', [Validators.required]),
    desc: new FormControl(null, [Validators.required]),
    category: new FormControl(null, [Validators.required]),
    // startDate: new FormControl(null,Validators.required),
    // endDate: new FormControl(null,Validators.required),
    quota: new FormControl(null, [Validators.required]),
    validityType: new FormControl(null, [Validators.required]),
    cycleType: new FormControl(null),
    quotaUnit: new FormControl(null),
    status: new FormControl(null, [Validators.required]),
    taxId: new FormControl(null),
    serviceId: new FormControl(null, [Validators.required]),
    plantype: new FormControl(null, [Validators.required]),
    validity: new FormControl(null),
    quotaunittime: new FormControl(null),
    qospolicyid: new FormControl(null, [Validators.required]),
    quotatype: new FormControl(null, [Validators.required]),
    quotatime: new FormControl(null, [Validators.required]),
    radiusprofileIds: new FormControl(null, [Validators.required]),
    planGroup: new FormControl(null, [Validators.required]),
    maxconcurrentsession: new FormControl('1', Validators.required),
    quotaintercom: new FormControl(''),
    quotadid: new FormControl(''),
    quotaunitdid: new FormControl(''),
    quotaunitintercom: new FormControl(''),
    didcheckbox: new FormControl(''),
    quotatimecheckbox: new FormControl(''),
    quotacheckbox: new FormControl(''),
    dataCategory: new FormControl(null),
    intercomcheckbox: new FormControl(''),
    param1: new FormControl(null),
    param2: new FormControl(null),
    param3: new FormControl(null),
    minPrice: new FormControl(null),
    tokenPrice: new FormControl(null),
    maxPrice: new FormControl(null),

    isIncludeTax: new FormControl(false), //new
    isDispalayUnlimited: new FormControl(false),
    vasPlanIds : new FormControl([]),
    allowVasSelection : new FormControl(false),
    dataQuotaselect: new FormControl(null, [Validators.required]),
    // timeQuotaselect: new FormControl(null, [Validators.required]),
    fup: new FormControl(null)

  });
  public policyList: any[] = [{
    policies_id: '',
    policies_name: ''
  }];
  public quotaUnitList: any[] = [{
    text: '',
    value: ''
  }];
  public quotaunittimeList: any[] = [{
    text: '',
    value: ''
  }];
  public quotaTypeList: any[] = [{
    text: '',
    value: ''
  }];
  public voiceTypeList: any[] = [{
    text: '',
    value: ''
  }];
  public voiceQuotaTypeList: any[] = [{
    text: '',
    value: ''
  }];
  qosList: any[] = [
    { id: '', name: '' }
  ];
  public serviceList: any[] = [
    { service_name: '', service_id: '' }
  ];
  statusList: any;
  // quotaTypeList : any[] = [
  //   {value_field:'data', display_field:'Data'},
  //   {value_field:'time', display_field:'Time'},
  //   {value_field:'both', display_field:'Both'}
  // ];
  public cycleTypeList
  public validityTypeList
  public planGroupList: PlanGroup[] = [];
  public planCategoryList: PlanCategory[] = [];
  public planTypeList: PlanType[] = [];
  public taxList: any[] = [{
    tax_name: '',
    tax_id: ''
  }];
  convertToTop: any;

  constructor(
    loginservice: LoginService,
    private ngbModalService: NgbModal,
    private packagesService: NPackagesService,
    private policiesService: PoliciesService,
    private planGroupService: PlanGroupService,
    private planCategoryService: PlanCategoryService,
    private planTypeService: PlanTypeService,
    private quotaTypeService: QuotaTypeService,
    private quotaTypeDataService: QuotaTypeDataService,
    private quotaTypeTimeService: QuotaTypeTimeService,
    private genericService: GenericTypeService,
    private qosPolicyServices: QosPolicyService,
    private taxService: TaxService,
    private servicesService: ServicesService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private commonservice: CommonListServicesService,
    private tosterservice: ToastrService,
    private datepipe:DatePipe,
    public dialog:MatDialog,
    private genericDataSharingService: DataSharingServiceService,
    public dialogRef: MatDialogRef<AddPackagesComponent>,
    private sharingdataService: DataSharingServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    private customeModalservice:CustomModalService,
  ) {
    super(tosterservice, router);
    localStorage.setItem('tabSelectionIndex','0');
  }

  ngOnInit() {
    this.init();
    this.getTableColumn();
    let id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.getById(Number(id));
      this.isupdate = true;
      this.packages_id = id;
      this.isDisabled_audit = false;
      this.show_update = true;
    }
    this.getPlanGroupList();
    this.getPlanCategoryList();
    this.getPlanTypeList();
    this.getTaxList();
    this.getServicesList();
    this.getPoliciesList();
    this.getVoiceQuotaType();
    this.getVoiceType();
    this.getQuotaType();
    this.getPlanStatus();
    this.getVASByType();
    this.getQosPolicyByTypes();
    this.getCycleType();
    this.getvalidityType();

    this.quotaTypeDataService.getQuotaTypeData().subscribe(
      result => {
        this.quotaUnitList = result.dataList;
        //Default Set:GB
        if (!this.show_update) {
          this.insertPackagesForm.get('quotaUnit').setValue(this.quotaUnitList[1].value);
        }

      });
    this.quotaTypeTimeService.getQuotaTypeTime().subscribe(
      result => {
        this.quotaunittimeList = result.dataList
        //Default Set:Hour
        if (!this.show_update) {
          this.insertPackagesForm.get('quotaunittime').setValue(this.quotaunittimeList[0].value);
        }

      });
    this.qosPolicyServices.getPolicyList().subscribe(
      result => { this.qosList = result.content; }
    );


  }

  getCycleType() {
    this.commonservice.getGenericCommonList('cycleType').subscribe((res) => {
      this.cycleTypeList = res.dataList
    });
  }
  getvalidityType() {
    this.commonservice.getGenericCommonList('validityType').subscribe((res) => {
      this.validityTypeList = res.dataList
    });
  }

  onChangeValidityType(event) {
    this.insertPackagesForm.get('cycleType').reset();
    this.insertPackagesForm.get('validity').reset();
    if (event) {
      this.insertPackagesForm.get('validityType').setValue(event.value)
      if (event.value === 'Fixed') {
            
        this.insertPackagesForm.get('validity').setValidators(Validators.required);
        this.insertPackagesForm.get('validity').setValue(0);
        this.insertPackagesForm.get('cycleType').setErrors(null);

        this.insertPackagesForm.get('cycleType').clearValidators();
        //this.insertPackagesForm.get('cycleType').reset();

        this.insertPackagesForm.updateValueAndValidity();
      }
      else {
                this.insertPackagesForm.get('cycleType').setErrors(null);
        this.insertPackagesForm.get('cycleType').setValidators(Validators.required);
        //this.insertPackagesForm.get('cycleType').reset();
        this.insertPackagesForm.get('validity').clearValidators();
        this.insertPackagesForm.get('validity').updateValueAndValidity();
        this.insertPackagesForm.updateValueAndValidity();
      }
    }
  }
  onChangeCycleType(event) {
    if (event) {
      this.insertPackagesForm.get('cycleType').setValue(event.value)
      this.entity.cycleType = event.value;
    }
  }

  getPlanStatus() {
    this.commonservice.getGenericRemarkType('planStatus').subscribe((res) => {
      this.statusList = res.dataList
    });
  }
  getQosPolicyByTypes() {
    this.packagesService.getQosPolicyByType().subscribe(response => {
      this.qosList1 = response.dataList;
    })
  }
  convertToUp() {
    this.packagesService.convertToTopUp().subscribe((res) => {
      if (res.convertVolToTopup) {
        if (res.convertVolToTopup.value === '1') {
          this.insertPackagesForm.get('validity').disable();
          this.insertPackagesForm.get('validity').updateValueAndValidity();
          this.insertPackagesForm.get('validity').setValue(0);
          this.insertPackagesForm.get('validity').updateValueAndValidity();
        } else {
          this.insertPackagesForm.get('validity').reset();
        }
      }
    });

  }
  getVASByType() {
    this.packagesService.getVASByType('VOICE').subscribe((res) => {
      if (res.dataList && res.dataList.length > 0) {
        res.dataList.map((res) => res.selected = false);
      }
      this.NEXG_VAS = res.dataList;
    });
    this.packagesService.getVASByType('OTT').subscribe((res) => {
      if (res.dataList && res.dataList.length > 0) {
        res.dataList.map((res) => res.selected = false);
      }
      this.VAS_OTT = res.dataList;
    });
    this.packagesService.getVASByType('IPTV').subscribe((res) => {
      if (res.dataList && res.dataList.length > 0) {
        res.dataList.map((res) => res.selected = false);
      }
      this.VAS_IPTV = res.dataList;
    });
    if(this.show_update){
      setTimeout(() => {
        this.getVASByTypeId();
      }, 800);
    }
  }

  getVASByTypeId(){
    for(let i=0; i< this.VAS_OTT.length; i++ ){
      for(let j=0; j<this.entity.vasPlanIds.length; j++){
        if(this.VAS_OTT[i].id == this.entity.vasPlanIds[j]){
          this.VAS_OTT[i].selected = true;
        }
      }
    }

    for(let i=0; i< this.VAS_IPTV.length; i++ ){
      for(let j=0; j<this.entity.vasPlanIds.length; j++){
        if(this.VAS_IPTV[i].id == this.entity.vasPlanIds[j]){
          this.VAS_IPTV[i].selected = true;
        }
      }
    }

    for(let i=0; i< this.NEXG_VAS.length; i++ ){
      for(let j=0; j<this.entity.vasPlanIds.length; j++){
        if(this.NEXG_VAS[i].id == this.entity.vasPlanIds[j]){
          this.NEXG_VAS[i].selected = true;
        }
      }
    }

  }

  didUnlimited(event) {
    if (event.target.checked === true) {
      this.insertPackagesForm.get('quotadid').disable();
      this.insertPackagesForm.get('quotaunitdid').disable();
      this.insertPackagesForm.get('quotadid').reset();
      this.insertPackagesForm.get('quotaunitdid').reset();

    } else if (event.target.checked === false) {
      this.insertPackagesForm.get('quotadid').enable();
      this.insertPackagesForm.get('quotaunitdid').enable();
    }
  }
  intercomUnlimited(event) {
    if (event.target.checked) {
      this.insertPackagesForm.get('quotaintercom').disable();
      this.insertPackagesForm.get('quotaintercom').reset();
      this.insertPackagesForm.get('quotaunitintercom').disable();
      this.insertPackagesForm.get('quotaunitintercom').reset();
    } else if (event.target.checked === false) {
      this.insertPackagesForm.get('quotaintercom').enable();
      this.insertPackagesForm.get('quotaunitintercom').enable();
    }
  }
  dataUnlimited(event?) {
    this.insertPackagesForm.get('quota').reset();
    if (event.target.checked) {
      this.fup = false;
      this.entity.quota = -1;
      this.entity.quotaUnit = "GB";
      this.isDisabled = true;
      this.entity.dataCategory = "Normal";
      this.fupDataFlag = true;
      this.quotaDataFlag = true;
      this.insertPackagesForm.get('quotaUnit').reset();
      this.insertPackagesForm.get('quota').disable();
      this.insertPackagesForm.get('quotaUnit').disable();
      this.insertPackagesForm.get('dataQuotaselect').disable();
      this.insertPackagesForm.get('quotaUnit').clearValidators();
      this.insertPackagesForm.get('quota').clearValidators();
      this.insertPackagesForm.get('quotaUnit').setValue("GB")
      this.insertPackagesForm.get('quotaUnit').updateValueAndValidity();
    } else if (event.target.checked === false) {
      this.entity.quota = undefined;
      this.entity.quotaUnit = undefined;
      this.isDisabled = false;
      this.insertPackagesForm.get('quota').enable();
      this.insertPackagesForm.get('dataQuotaselect').enable();
      //this.insertPackagesForm.get('quota').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaUnit').enable();
      this.insertPackagesForm.get('quotaUnit').setValidators([Validators.required]);
    }

  }

  quotaTypeChange(data)
  {
    this.entity.quota = undefined;
    this.entity.quotatime = undefined;
    this.isDisabledQcheck = false;
    this.insertPackagesForm.get('quotatimecheckbox').reset();
    this.insertPackagesForm.get('quotacheckbox').reset();
    this.insertPackagesForm.get('fup').reset();
    this.isDisabled = false;
    this.fup = false;
    this.unlimited = false;
    if(data.value == "Both")
    {
      this.insertPackagesForm.get('dataQuotaselect').enable();
      this.entity.dataCategory = 'Normal'
      // this.insertPackagesForm.get('timeQuotaselect').enable();
    }
    if(data.value == "Time")
    {
      // this.insertPackagesForm.get('timeQuotaselect').enable();
    }
    if(data.value == "Data")
    {
      this.insertPackagesForm.get('dataQuotaselect').enable();
    }

    if (data.value === 'Data') {

      this.timePlanDisable = true;
      this.dataPlanDisable = false;
      this.insertPackagesForm.get('quotatime').reset();
      this.insertPackagesForm.get('quotatime').disable();
      // this.insertPackagesForm.get('timeQuotaselect').disable();
      this.insertPackagesForm.get('quotaunittime').reset();
      this.insertPackagesForm.get('quotaunittime').disable();
      this.insertPackagesForm.get('quotaintercom').disable();
      this.insertPackagesForm.get('quotaintercom').reset();
      this.insertPackagesForm.get('quotaunitintercom').disable();
      this.insertPackagesForm.get('quotaunitintercom').reset();
      this.insertPackagesForm.get('quotadid').disable();
      this.insertPackagesForm.get('quotadid').reset();
      this.insertPackagesForm.get('quotaunitdid').disable();
      this.insertPackagesForm.get('quotaunitdid').reset();
      this.insertPackagesForm.get('quota').enable();
      this.insertPackagesForm.get('quota').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaUnit').enable();
      this.insertPackagesForm.get('quotaUnit').setValidators([Validators.required]);
    }  if (data.value === 'Time') {
      this.timePlanDisable = false;
      this.dataPlanDisable = true;
      this.insertPackagesForm.get('quota').reset();
      this.insertPackagesForm.get('quota').disable();
      this.insertPackagesForm.get('quotaUnit').clearValidators();
      this.insertPackagesForm.get('quotaUnit').updateValueAndValidity();
      this.insertPackagesForm.get('quotaUnit').reset();
      this.insertPackagesForm.get('quotaUnit').disable();
      this.insertPackagesForm.get('dataQuotaselect').disable();
      this.insertPackagesForm.get('quotaintercom').disable();
      this.insertPackagesForm.get('quotaintercom').reset();
      this.insertPackagesForm.get('quotaunitintercom').disable();
      this.insertPackagesForm.get('quotaunitintercom').reset();
      this.insertPackagesForm.get('quotadid').disable();
      this.insertPackagesForm.get('quotadid').reset();
      this.insertPackagesForm.get('quotaunitdid').disable();
      this.insertPackagesForm.get('quotaunitdid').reset();
      this.insertPackagesForm.get('quotatime').enable();
      this.insertPackagesForm.get('quotatime').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaunittime').enable();
      this.insertPackagesForm.get('quotaunittime').setValidators([Validators.required]);
    } if (data.value === 'Both' && this.insertPackagesForm.get('quotacheckbox').value === true) {

      this.timePlanDisable = false;
      this.dataPlanDisable = false;
      this.insertPackagesForm.get('quota').enable();
      this.insertPackagesForm.get('quota').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaUnit').enable();
      this.insertPackagesForm.get('quotaUnit').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotatime').enable();
      this.insertPackagesForm.get('quotatime').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaunittime').enable();
      this.insertPackagesForm.get('quotaunittime').setValidators([Validators.required]);
    } if (data.value === 'Both' && this.insertPackagesForm.get('quotatimecheckbox').value === true) {
      this.timePlanDisable = false;
      this.dataPlanDisable = false;
      this.insertPackagesForm.get('quota').enable();
      this.insertPackagesForm.get('quota').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaUnit').enable();
      this.insertPackagesForm.get('quotaUnit').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotatime').enable();
      this.insertPackagesForm.get('quotatime').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaunittime').enable();
      this.insertPackagesForm.get('quotaunittime').setValidators([Validators.required]);
    } if (data.value === 'Both') {

      this.timePlanDisable = false;
      this.dataPlanDisable = false;
      this.insertPackagesForm.get('quota').enable();
      this.insertPackagesForm.get('quota').setValidators([Validators.required]);
      this.insertPackagesForm.get('quota').updateValueAndValidity();
      this.insertPackagesForm.get('quotaUnit').enable();
      this.insertPackagesForm.get('quotaUnit').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotatime').enable();
      this.insertPackagesForm.get('quotatime').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotatime').updateValueAndValidity();
      this.insertPackagesForm.get('quotaunittime').enable();
      this.insertPackagesForm.get('quotaunittime').setValidators([Validators.required]);
    }
  }


  fupChanged(value: boolean) {
    this.fup = value;
    this.unlimited = false;
    if (this.fup === true) {
      this.isDisabledQcheck = true;
      this.entity.dataCategory = "FUP";
    }
    else {
      this.isDisabledQcheck = false;
      this.entity.dataCategory = "Normal";

    }
  }

  timeUnlimited(event?) {
    this.insertPackagesForm.get('quotatime').reset();
    if (event.target.checked) {
      this.entity.quotatime = -1;
      this.entity.quotaunittime = "Hour";
      this.quotaTimeFlag = true;
      this.insertPackagesForm.get('quotaunittime').reset();
      this.insertPackagesForm.get('quotatime').disable();
      this.insertPackagesForm.get('quotaunittime').disable();
      // this.insertPackagesForm.get('timeQuotaselect').disable();
      this.insertPackagesForm.get('quotatime').clearValidators();
      this.insertPackagesForm.get('quotaunittime').clearValidators();
      this.insertPackagesForm.get('quotaunittime').setValue("Hour");
      this.insertPackagesForm.get('quotaunittime').updateValueAndValidity()

    } else if (event.target.checked === false) {
      this.quotaTimeFlag = false;
      this.entity.quotatime = undefined;
      this.entity.quotaunittime = undefined;

      this.insertPackagesForm.get('quotatime').enable();
      this.insertPackagesForm.get('quotaunittime').enable();
      // this.insertPackagesForm.get('timeQuotaselect').enable();
      this.insertPackagesForm.get('quotatime').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotaunittime').setValidators([Validators.required]);
    }
  }
  
  setValidation() {
    if(this.insertPackagesForm.get('validityType').value === 'Cycle'){
      this.insertPackagesForm.get('cycleType').setValidators(Validators.required);
      this.insertPackagesForm.get('cycleType').updateValueAndValidity();
    }

    if(this.insertPackagesForm.get('validityType').value === 'Fixed'){
      this.insertPackagesForm.get('validity').setValidators(Validators.required);
      this.insertPackagesForm.get('validity').updateValueAndValidity();
    }

    if (this.insertPackagesForm.value.dataCategory === null) {
      this.insertPackagesForm.get('dataCategory').setValue('Normal');
      this.entity.dataCategory = 'Normal';
    }else{
      this.entity.dataCategory = this.insertPackagesForm.value.dataCategory;
    }
  }

  getQuotaType() {
    this.quotaTypeService.getQuotaType().subscribe((result) => {
      this.quotaTypeList = result.dataList;
    });
  }

  getVoiceType() {
    this.genericService.getGenericVoiceType().subscribe((result) => {
      this.voiceTypeList = result.dataList;
    });
  }

  getVoiceQuotaType() {
    this.genericService.getGenericVoiceQuotaType().subscribe((result) => {
      this.voiceQuotaTypeList = result.dataList;
    });
  }

  getTaxList(tax_id?: any) {
    this.taxService.getTaxList().subscribe(result => {
      this.taxList = result.content;
      if (tax_id) {
        this.insertPackagesForm.patchValue({ tax_id: tax_id });
      }
    });
  }

  getServicesList(service_id?: any) {
    this.servicesService.getServiceList().subscribe(
      result => {
        this.serviceList = result.content;

        if (service_id) {
          this.insertPackagesForm.patchValue({ service_id: service_id });
        }
      });
  }

  getPoliciesList(policies_id?: any) {
    let type = 'Radius'
    this.policiesService.getPolicies(type).subscribe(
      result => {
        this.policyList = result.content;
        if (policies_id) {
          this.insertPackagesForm.patchValue({ policies_id: policies_id });
        }
      }
    );
  }

  getPlanGroupList() {
    this.planGroupService.getPlanGroupList().subscribe((result) => {
      this.planGroupList = result.dataList;
    });
  }

  getPlanCategoryList() {
    this.planCategoryService.getPlanCategoryList().subscribe((result) => {
      this.planCategoryList = result.dataList;
    });
  }

  getPlanTypeList() {
    this.planTypeService.getPlanTypeList().subscribe((result) => {
      this.planTypeList = result.dataList;
    });
  }


  quotaType(objValue){
    console.log("objValue",objValue);
  }

  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  setUpdateValueInForm(packages_Data) {
    this.show_update = true;
    this.selectForUpdate = packages_Data;
    ////console.log("demo::",this.selectForUpdate)
    this.insertPackagesForm.get('quotaUnit').setValue(this.selectForUpdate.quotaUnit)
    this.insertPackagesForm.get('quotaUnit').updateValueAndValidity();
    this.insertPackagesForm.patchValue(packages_Data);
    if (packages_Data.quotadid === -1) {
      this.insertPackagesForm.get('quotadid').disable();
      this.insertPackagesForm.get('quotadid').reset();
      this.insertPackagesForm.get('didcheckbox').setValue(true);
    }
    if (packages_Data.quotaintercom === -1) {
      this.insertPackagesForm.get('quotaintercom').disable();
      this.insertPackagesForm.get('quotaintercom').reset();
      this.insertPackagesForm.get('intercomcheckbox').setValue(true);
    }
    if (packages_Data.quota === -1) {
      this.insertPackagesForm.get('quota').disable();
      this.insertPackagesForm.get('quota').reset();
      this.insertPackagesForm.get('quotacheckbox').setValue(true);
    }
    if (packages_Data.quotatime === -1) {
      this.insertPackagesForm.get('quotatime').disable();
      // this.insertPackagesForm.get('timeQuotaselect').disable();
      this.insertPackagesForm.get('quotatime').reset();
      this
      this.insertPackagesForm.get('quotatimecheckbox').setValue(true);
    }
    if (packages_Data.dataCategory === "FUP") {
      this.fup = true;
      this.isDisabledQcheck = true;
    } else {
      this.fup = false;
    }
    if(this.selectForUpdate.planGroup == 'Volume Booster'){
      this.insertPackagesForm.get('validityType').disable();
      this.insertPackagesForm.get('validity').disable();
    } else {
      this.insertPackagesForm.get('validityType').enable();
      this.insertPackagesForm.get('validity').enable();
    }
  }



  minmax() {
    if (this.insertPackagesForm.get('minPrice').value && this.insertPackagesForm.get('maxPrice').value) {
      if (this.insertPackagesForm.get('maxPrice').value < this.insertPackagesForm.get('minPrice').value) {
        this.tosterservice.error("Max Price Can't be Less then Min Price");
        this.minmaxValueInvalid = true;
      }
      else
      {
        this.minmaxValueInvalid = false;
      }
    }
  }
  checktoken() {
    if (this.insertPackagesForm.get('offerprice').value && this.insertPackagesForm.get('tokenPrice').value) {
      if (this.insertPackagesForm.get('tokenPrice').value > this.insertPackagesForm.get('offerprice').value) {
        this.tosterservice.error("Token Price Can't be greater then Offer Price");
        this.tokenPriceInvalid = true;
      }
      else
      {
        this.tokenPriceInvalid = false;
      }
    }
  }


  formSubmit() {
      let temp = []
      if(this.NEXG_VAS.length > 0){
        this.NEXG_VAS.forEach(res => {
          if(res.selected == true){
            temp.push(res.id)
          }
        })
      }
      
      if(this.VAS_OTT.length > 0){
        this.VAS_OTT.forEach(res => {
          if(res.selected == true){
            temp.push(res.id)
          }
        })
      }
      
      if(this.VAS_IPTV.length > 0){
        this.VAS_IPTV.forEach(res => {
          if(res.selected == true){
            temp.push(res.id)
          }
        })
      }
      this.entity.vasPlanIds = temp
    let self = this;
    console.log(this.insertPackagesForm);
    if (self.validateForm() && !self.minmaxValueInvalid && !self.tokenPriceInvalid) {
      alertify.confirm("Package", "Are you sure you want to save this record?",
        function () {
          if(self.show_update)
          {
            self.onUpdate();
          }
          if(!self.show_update)
          {
            self.onSave();
          }
        },
        function () {

        });
    }
    else if(self.minmaxValueInvalid)
    {
      self.tosterservice.error("Max Price Can't be Less then Min Price");
    }
    else if(self.tokenPriceInvalid)
    {
      this.tosterservice.error("Token Price Can't be greater then Offer Price");
    }
    else
    {
      this.tosterservice.error("Please fill all the details!");
    }
  }


  public getFormGroup(): FormGroup {
    return this.insertPackagesForm;
  }

  public getId(): number {
    return this.entity.id;

  }

  get f() {
    return this.insertPackagesForm.controls;
  }

  public getNewModal(): PackageModel {
    return new PackageModel();
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }

  public getModuleHomeUrl(): string {
    this.url = 'plan-management/packages'
    return this.url;
  }

  BackToPackages() {
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['plan-management/packages']);
    }
  }
  onPlanGroupChange(event) {

    console.log("plan group",event)
   
    ////console.log(event)
    if (event) {
      this.entity.planGroup = event.text
      
      if (this.entity.planGroup === 'Volume Booster') {
        this.entity.validityType = 'Fixed';
        this.insertPackagesForm.get('validityType').disable();
        this.insertPackagesForm.get('validity').disable();
        setTimeout(() => {
          this.convertToUp();
        }, 2000);
        this.quotaTypeList = this.quotaTypeList.filter((res) => {
          return res.id !== 35;
        });
  
      }
      else {
        this.insertPackagesForm.get('validityType').reset()
        this.insertPackagesForm.get('validityType').enable();
        this.insertPackagesForm.get('validity').enable();
        this.insertPackagesForm.updateValueAndValidity();
        this.getQuotaType();
      }
      if (event.text === "Renew" || event.text == "Registration") {
        this.timePlanDisable = false;
        this.dataPlanDisable = false
      }
      else {
        this.timePlanDisable = true;
        this.dataPlanDisable = true;
      }
    }
    else {
      this.getQuotaType();
    }

  }

  spaceNotAllow(event) {
    if (event.which == 32) {
      // alertify.error("Space Not Allowed");
      return true;
    }
  }

  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }
  
  closeDialog(){
    this.dialogRef.close();
   }

  checkAllowCustomer(event)
  {
    let temp = []
      this.NEXG_VAS.forEach(res => {
        if(res.selected == true){
          temp.push(res.id)
        }
      })
      
      this.VAS_OTT.findIndex(res => {
        if(res.selected == true){
          temp.push(res.id)
        }
      })
      
      this.VAS_IPTV.findIndex(res => {
        if(res.selected == true){
          temp.push(res.id)
        }
      })
      if(temp && temp.length != 0){
      let self = this
      alertify.confirm("VAS Selection","All selected VAS will be removed.<br> Are you sure want to do ?",
      function() {
        self.NEXG_VAS.map(res => res.selected = false);
        self.VAS_OTT.map(res => res.selected = false);
        self.VAS_IPTV.map(res => res.selected = false);
        // self.entity.allowVasSelection = event.target.value;
      },
      function() {
        // console.log('self.entity.allowVasSelection =>',self.entity.allowVasSelection);
        
        self.entity.allowVasSelection = !self.entity.allowVasSelection;
      });
    }else{
      // this.entity.allowVasSelection = event.target.checked;
    }
  }

  openModal(id, qos_id) {
    if (id === "qos-policy") {
      this.openQosPopup = true;
      setTimeout(() => {
        this.customeModalservice.open(id);
      }, 500);
    }
  }
  closeQosPolicyPopup(event) {
    this.openQosPopup = false;
  }
  
  getService(): IBaseService<any> {
    return this.packagesService;
  }

  getData(): PackageModel {
    return this.entity;
  }

  setData(data) {
    
    this.entity = data;
    this.entity.billingCycle = data.billingCycle;
    if (this.entity.quota == -1) {
      this.unlimited = true;
      this.isDisabled = true;
      this.insertPackagesForm.get('quota').disable();
      this.insertPackagesForm.get('dataQuotaselect').disable();
    }
    if (this.entity.quotatime == -1) {
      this.timeQuotaUnlimited = true;
      this.quotaTimeFlag = true;
      this.insertPackagesForm.get('quotatime').disable();
      this.insertPackagesForm.get('quotaunittime').disable();
    }
    if (this.entity.quotatype == "Data") {
      this.dataPlanDisable = false;
      this.insertPackagesForm.get('quotatime').disable();
      this.insertPackagesForm.get('quotaunittime').disable();
    }
    if (this.entity.quotatype == "Time") {
      this.timePlanDisable = false;
      this.insertPackagesForm.get('quota').disable();
      this.insertPackagesForm.get('dataQuotaselect').disable();
    }
    if (this.entity.quotatype == "Both" && this.entity.quota == -1) {
      this.timePlanDisable = false;
      this.dataPlanDisable = false;
      this.unlimited = true;
      this.isDisabled = true;
      this.insertPackagesForm.get('quota').disable();
      this.insertPackagesForm.get('dataQuotaselect').disable();
    }else if (this.entity.quotatype === 'Both' && this.entity.quotatime == -1) {
      this.timePlanDisable = false;
      this.dataPlanDisable = false;
      this.timeQuotaUnlimited = true;
      this.insertPackagesForm.get('quotatime').disable();
      this.insertPackagesForm.get('quotaunittime').disable();
    } else if (this.entity.quotatype === 'Both') {
      this.timePlanDisable = false;
      this.dataPlanDisable = false;
      this.insertPackagesForm.get('quota').enable();
      this.insertPackagesForm.get('quota').setValidators([Validators.required]);
      this.insertPackagesForm.get('quota').updateValueAndValidity();
      this.insertPackagesForm.get('quotaUnit').enable();
      this.insertPackagesForm.get('quotaUnit').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotatime').enable();
      this.insertPackagesForm.get('quotatime').setValidators([Validators.required]);
      this.insertPackagesForm.get('quotatime').updateValueAndValidity();
      this.insertPackagesForm.get('quotaunittime').enable();
      this.insertPackagesForm.get('quotaunittime').setValidators([Validators.required]);
    }
    if (this.entity.dataCategory == "FUP") {
      this.fup = true;
      this.isDisabledQcheck = true;
    }
    // if (this.entity.dataCategory == "Normal") {
    //   this.unlimited = true;
    //   this.insertPackagesForm.get('quota').disable();
    //   this.insertPackagesForm.get('dataQuotaselect').disable();
    // }
    if (data.isIncludeTax === true) {
      this.offerpriceInclTax = data.offerprice + data.taxamount;
      data.offerprice += data.taxamount;
      let mincheck = data.minPrice ? data.minPrice : ''
      let minchecktax = data.minPriceTax ? data.minPriceTax : ''
      let maxcheck = data.maxPrice ? data.maxPrice : ''
      let maxchecktax = data.maxPriceTax ? data.maxPriceTax : ''
      this.entity.minPrice = mincheck + minchecktax
      this.entity.maxPrice = maxcheck + maxchecktax
      //this.offerpriceInclTax = data.offerprice
    }

    if (data.isIncludeTax === false) {
      this.offerpriceInclTax = data.offerprice;
      this.entity.minPrice = data.minPrice ? data.minPrice : null
      this.entity.maxPrice = data.maxPrice ? data.maxPrice : null
      //this.offerpriceInclTax = data.offerprice + data.taxamount;
    }
  }


  getGenericDataService(){
    return this.genericDataSharingService
    }

    //----------------------------------

    getTableColumn() {
      let self = this;
      this.allColumns = [
        {
          colKey: 'index',
          default: 'ID',
          rowKey: 'id',
          width: '3%',
          isFilterAllow: false,
          isNumber: true,
        },
        {
          colKey: 'name',
          default: 'name',
          rowKey: 'name',
          width: '20%',
          isNumber: false,
          isFilterAllow: true,
          enableEdit: true,
          enableDelete: true,
          enableSort: true,
        },
        {
          colKey: 'type',
          default: 'type',
          rowKey: 'type',
          width: '20%',
          isNumber: false,
          isFilterAllow: true,
          enableEdit: true,
          enableDelete: true,
          enableSort: true,
        },
        {
          colKey: 'actionCol',
          default: 'Action',
          rowKey: 'id',
          enableEdit: false,
          enableDelete: false,
          isFilterAllow: false,
          enableSort: false,
          isExpandable: false,
          isNumber: false,
          width: '1%',
          isStickyEnd: true,
        },
      ];

      this.allColumns.forEach(element => {
        element.isFilterAllow = false;
      });
      this.getTableSetting();
      this.getTableSearchSetting();  
    }

    getTableSetting()
    {
      this.neoGridSettings = {
        showAdvanceSearch: false,
        showFooter: false,
        showPagination: true,
        tableId: this.tableKeyEnum.SELECT_VAS,
        perPage: this.paginationData.rowsPerPage,
        pageNumber:this.paginationData.pageNumber - 1,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        serverSideSortPagination: true,
        pageTotalLength: 0,
        sort: true,
        defaultSortCol: '',
        defaultSortDirection: 'asc',
        // enableMultiDelete: true,
        columns: this.allColumns,
        deleteMsgBoxTitle: 'Are you sure to delete this Country ?',
        deleteMsgBoxDetail: 'Country Name :${country_name} ',
      };
    }

    getTableSearchSetting()
    {
      this.searchSettings = [
        {
          display_field: 'Country Name',
          value_field: 'country_name',
          control_setting: [
            {
              control_type: SearchControlTypeEnum.TEXT,
              display_style: '',
            },
          ],
          data_type: SearchDataTypeEnum.STRING,
        },
        {
          display_field: 'Status',
          value_field: 'status',
          control_setting: [
            {
              control_type: SearchControlTypeEnum.SELECT,
              display_style: '',
            },
          ],
          data_type: SearchDataTypeEnum.BOOLEAN,
          select_items: [
            {
              display_field: 'All',
              value_field: ['Y', 'N'],
            },
            {
              display_field: 'True',
              value_field: 'Y',
            },
            {
              display_field: 'False',
              value_field: 'N',
            },
          ],
        },
      ];
    }

    ngAfterViewInit() {
      this.allColumns.forEach((col) => {
        if (col.colKey == 'status') {
          col.customControls = [this.statusWrapperRef];
        }
        if (col.colKey == 'actionCol') {
          col.customControls = [this.actionBtnRef];
        }
      });
    }

    openVasPopUp(content)
    {
      this.ngbRef = this.ngbModalService.open(content, { size: 'xs', backdrop: 'static' });
    }

    closeModal() {
      this.ngbModalService.dismissAll();
     }

     refreshDataSource(data) {
      if (data) {
        this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
      }
    }

          
    onclick() {
      this.closeModal();
    }

    checkDisable(data){
      if(this.VASSelectedListFromAcrd.length > 0){
        for(let i = 0; i<this.VASSelectedListFromAcrd.length; i++){
          if(data.selected === true){
            return true;
          }
        }
      }
    }

    onDelete(data) {
      let temp = this.vasSelectionList.findIndex(res => res.name === data.name)
      this.vasSelectionList.splice(temp, 1);
      this.VASSelectedListFromAcrd = this.vasSelectionList;
      //this.refreshDataSource(this.vasSelectionList);
    }

    deleteSelectedVoiceDetails(index) {
      this.VOICE_LIST.splice(index, 1);
      this.vasSelectionList.splice(index, 1);
      this.VASSelectedListFromAcrd = this.vasSelectionList;
    }

    deleteSelectedOttDetails(index) {
      this.OTT_LIST.splice(index, 1);
      this.vasSelectionList.splice(index, 1);
      this.VASSelectedListFromAcrd = this.vasSelectionList;
    }
    
    deleteSelectedIptvDetails(index) {
      this.IPTV_LIST.splice(index, 1);
      this.vasSelectionList.splice(index, 1);
      this.VASSelectedListFromAcrd = this.vasSelectionList;
    }

    deselectRadio(id,i){
      if(id == 'iptv'){
        this.VAS_IPTV.map((ls) => ls.selected = false);
          this.VAS_IPTV[i].selected = true;
      }
      if(id == 'voice'){
          this.NEXG_VAS.map((ls) => ls.selected = false);
          this.NEXG_VAS[i].selected = true;
      }
      if(id == 'ott'){
          this.VAS_OTT.map((ls) => ls.selected = false);
          this.VAS_OTT[i].selected = true;
      }
    }
}
