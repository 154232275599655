// import { IBaseService } from '../service/ibase-service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import { VALIDATION_CONFIGURATION } from 'src/environments/configuration';
// import { RouteInfo } from '../../shared/sidebar/sidebar.metadata';
// import { environment } from '../../../environments/environment';
// import { AclDataModal } from '../../shared/sidebar/acl-data-modal';
// import { getServers } from 'dns';
// import { Row } from 'ng2-smart-table/lib/data-set/row';
// import { UserModal } from '../../modules/user/modal/user-modal';
import { DatePipe } from '@angular/common';
import { BaseListComponent } from './base-list-component';
import { PaginationData } from '../../core/modal/pagination-data';
import { OrderingData } from '../../core/modal/ordering-data';
import { ResponseCode } from '../../core/constant/response-code';
import { IBaseService } from '../../services/ibase-service';
import { SearchData } from '../../core/modal/search-data';
import { SearchService } from '../../services/search.service';

import * as alertify from 'alertifyjs';
import { TableKeyEnum } from '../../core/enum/table-key.enum';
import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';
import { ChangeDetectorRef } from '@angular/core';

export abstract class AbstractBaseListComponent<T> implements BaseListComponent<T> {
  public orderingData: OrderingData;
  public searchData: SearchData[];
  public totalRecords: number;
  public paginationData: PaginationData;
  public id: number;
  // public validationConfiguration = VALIDATION_CONFIGURATION;
  public neoTableSetting: any;
  public neoTableSearchSetting: any;
  // public source: LocalDataSource;
  public isAdvancedSearchEnable: boolean = false;

  public isDeleteAllow: boolean = true;
  public isEditAllow: boolean = true;
  public isViewAllow: boolean = true;
  public isAddAllow: boolean = true;

  

  // public activeUser: UserModal;
  public isUserAllow: boolean;

  public sortDate = (direction: any, a: string, b: string) => {
    let firstDate, secondDate;
    const firstDateParts = a ? a.split('-') : '';
    const secondDateParts = b ? b.split('-') : '';
    if (firstDateParts && firstDateParts.length !== 0) {
      firstDate = new Date(+firstDateParts[2], +firstDateParts[1] - 1, +firstDateParts[0]);
    }
    if (secondDateParts && secondDateParts.length !== 0) {
      secondDate = new Date(+secondDateParts[2], +secondDateParts[1] - 1, +secondDateParts[0]);
    }
    const first = Number(new DatePipe('en-EN').transform(firstDate, 'yyyyMMdd'));
    const second = Number(new DatePipe('en-EN').transform(secondDate, 'yyyyMMdd'));

    if (first < second) {
      return -1 * direction;
    }
    if (first > second) {
      return direction;
    }
    return 0;
  };

  constructor(private toastr: ToastrService, private searchSrv?: SearchService) {}
  abstract getService(): IBaseService<T>;
  abstract setList(Array);
  abstract getTableColumn(): any;
  abstract getTableSetting(): any;
  abstract getTableSearchSetting(): any;
  public abstract onDeleteClick(data: T);
  public abstract onEditClick(data: T);
tableName:string;
  init() {
    this.initNeoColumns();
    let GlobalName = this.tableName +' Global'
    let userId = localStorage.getItem('userId');
    let tableData = JSON.parse(localStorage.getItem('tableData_' + userId));
    if(tableData[GlobalName] && tableData[GlobalName].filterValue){
    
    }
    else{
      this.onOrderingChange();
    }
    this.authRole();
  }

  setDefaultPageSize(tableName){
    this.tableName =  tableName;
    let userId = localStorage.getItem('userId');
    this.paginationData = new PaginationData();
    if(localStorage.getItem('tableData_' + userId)){
      let tableData = JSON.parse(localStorage.getItem('tableData_' + userId));
      if(tableName){
        if(tableData[tableName])
        this.paginationData.rowsPerPage = tableData[tableName];
        let name = tableName + ' Number' 
        if(tableData[name]){
          if(localStorage.getItem('partnerViewFlag') == 'true'){
            this.paginationData.pageNumber = 1
          }
          else{
            this.paginationData.pageNumber = Number(tableData[name]);
          }  
        }  
      }
     

    }
   
  }

  delete(modal: T) {
    this.getService()
      .delete(modal)
      .subscribe(
        (response) => {
          if (response.responseCode != ResponseCode.OK) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.onDeleteSuccess();
        },
        (error) => {
          this.onResponseError(error.message);
        }
      );
  }

  fillTemplate(templateString, templateVars) {
    var parsed = templateString;
    if (templateVars) {
      Object.keys(templateVars).forEach((key) => {
        const value = templateVars[key];
        parsed = parsed.replace('${' + key + '}', value);
      });
    }
    return parsed;
  }

  commonDeleteMsgBox(settings,event,callback): void {
    

    let self = this;
    let msgDetail = this.fillTemplate(settings.deleteMsgBoxDetail, event);
    alertify
      .confirm(
        settings.deleteMsgBoxTitle,
        msgDetail,
        function() {
          if (callback) {
            //callback(event);
            callback({ deleteClicked: true,event:event });
          }
        },
        function() {
          if (callback) {
            //callback(event);
            callback({ deleteClicked: false,event:event });
          }
        }
      )
      .setting('labels', { ok: 'Yes', cancel: 'No' })
      .set('defaultFocus', 'cancel');
  }

  getList(paginationData: PaginationData, orderingData: OrderingData, searchData?: SearchData[],id?:number) {
    // this.loadingSrv.show();
    if (paginationData.pageNumber <= 0) {
      paginationData.pageNumber = 1;
    }
    this.getService()
      .getAll(paginationData, orderingData, searchData,id)
      .subscribe(

        (response) => {
          // this.loadingSrv.hide();

          if (response.responseCode != ResponseCode.OK) {
            this.onResponseError(response.responseMessage);
            return;
          }
          this.totalRecords = response.totalRecords;
          this.setList(response.dataList);
        },
        (error) => {
          // this.loadingSrv.hide();
          this.onResponseError(error.message);
        }
      );
  }

  public onDeleteSuccess() {
    this.toastr.success('Data has been deleted successfully.', 'Success');
    this.onDeleteSuccessed();
  }

  public setSearchData(data)
  {
    this.searchData = new Array<SearchData>();
    this.searchData.push(data);
    
  }

  public onDeleteSuccessed() {
    this.getList(this.paginationData, this.orderingData, this.searchData,this.id);
  }

  public onResponseError(message: string) {
    this.toastr.error(message, 'Error');
  }

  onPaginationChange(paginationData: PaginationData,searchData?,id?) {
    this.paginationData = paginationData;
    if(searchData){
      this.searchData = searchData;
    }
    this.getList(this.paginationData, this.orderingData, this.searchData,this.id);
  }

  private initNeoColumns() {
    this.neoTableSetting = this.getTableSetting();

    this.neoTableSearchSetting = this.getTableSearchSetting();
  }

  setOrderingData(orderingData: OrderingData = null) {
    if (!orderingData) {
      this.orderingData = new OrderingData();
      this.orderingData.column = 'id';
      this.orderingData.descending = false;
    }
  } 

  onOrderingChange(orderData: OrderingData = null) {
    if (orderData) {
      this.orderingData = orderData;
    } else {
      this.setOrderingData();
    }
    this.getList(this.paginationData, this.orderingData, this.searchData,this.id);
  }

  onFilterChange(filterObj) {
    
    let temp = new Array<SearchData>();
    if (this.searchSrv) {
      let serverFilterObj: SearchData[] = this.searchSrv.getServerFilterObj();
      if (filterObj) {
        let searchData = new SearchData();
        searchData = filterObj;
        if (!Array.isArray(searchData) && searchData.filterColumn) {
          if (searchData.filterValue) {
            serverFilterObj = this.searchSrv.setServerFilterObj([searchData]);
          } else {
            serverFilterObj = this.searchSrv.resetGlobalSearchServerFilterObj();
          }
        } else {
          
          serverFilterObj = this.searchSrv.setServerFilterObj(filterObj);
        }
      } else {
        serverFilterObj = this.searchSrv.resetAdvanceSearchServerFilterObj();
      }
      
      if(this.searchData && this.searchData.length > 0 ){
        this.searchData.forEach((res)=>{
          serverFilterObj.push(res)
        })
      }
      
      this.searchData = JSON.parse(JSON.stringify(serverFilterObj));
  
      this.paginationData.pageNumber=this.neoTableSetting.pageNumber=1;
      this.getList(this.paginationData, this.orderingData, this.searchData,this.id);
      this.searchData = new Array<SearchData>()
      return serverFilterObj;
    } else {
      return null;
    }
  }

  onSearch(query: string = '') {
    // if (!this.source) {
    //   return;
    // }
    // if (!query) {
    //   this.source.setFilter([]);
    //   return;
    // }
    // let filter: Array<{ field: string; search: string }> = new Array<{ field: string; search: string }>();
    // let keys: string[] = Object.keys(this.getTableColumn());
    // keys.forEach((keyName) => {
    //   filter.push({ field: keyName, search: query });
    // });
    // this.source.setFilter(filter, false);
  }

  public onAdvanceSearchClick() {
    this.isAdvancedSearchEnable = !this.isAdvancedSearchEnable;
  }

  public hideDeleteButton(): boolean {
    return false;
  }

  // onSmartTableEditClick(eventData: Row) {
  //   this.onEditClick(eventData.getData());
  // }

  // onSmartTableDeleteClick(eventData: Row) {
  //   this.onDeleteClick(eventData.getData());
  // }

  // Created To Fix PDF, Excels Data Issues
  refreshLocalData() {
    setTimeout(() => {
      this.getList(this.paginationData, this.orderingData, this.searchData,this.id);
    }, 1500);
  }

  public authRole() {
    // Id 1 for admin, Id 2 for staff, Id 3 for SuperAdmin, Id 4 for other.
    // this.activeUser = JSON.parse(localStorage.getItem('activeUser'));
    // if (this.activeUser && this.activeUser.role && this.activeUser.role.roleid) {
    //   if (this.activeUser.role.roleid === 1 || this.activeUser.role.roleid === 3) {
    //     this.isUserAllow = true;
    //   }
    //   if (this.activeUser.role.roleid === 2 || this.activeUser.role.roleid === 4) {
    //     this.isUserAllow = false;
    //   }
    // }
  }

  setAutoSelectDropDownList(data){
    if(data && data.length == 1){
      return data[0];
    }
  }
}
