import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, map } from 'rxjs/operators';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { AbstractBaseAddUpdateComponent, DialogPassData } from '../../../../components/base/abstract-base-add-update-component';
import { LoadingService } from '../../../../components/loading/loading.service';
import { IBaseService } from '../../../../services/ibase-service';
import { CaseReasonModel } from '../model/case-reason-model';
import { CaseReasonService } from '../service/case-reason.service';
import { TatConsiderationService } from '../../../../services/tat_consideration/tat-consideration.service';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';
import { ResponseCode } from '../../../../core/constant/response-code';
import { DataSharingServiceService } from '../../../../pages/data-sharing-service.service';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
//import { IpPoolConfigDetailComponent } from 'src/app/pages/network-management/ip-pool-config/ip-pool-config-detail/ip-pool-config-detail.component';


export interface diologData{
  id:number;
}
@Component({
  selector: 'app-case-reason-add-update',
  templateUrl: './case-reason-add-update.component.html',
  styleUrls: ['./case-reason-add-update.component.scss']
})
export class CaseReasonAddUpdateComponent implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  //@ViewChild('algoType', {static:false}) algoTypeRef: TemplateRef<any>;
  public entity: CaseReasonModel = new CaseReasonModel();
  public entity_id: any = null;
  form: FormGroup;
  url: string;
  commonStatus: any
  commonTat: any
  public sub: any = null;
  show_update:any = false;
  selectForUpdate: any;
  serviceAreaList : any =[];
  serviceAreaId : any;
  teamId: any;
  teamType : any;
  allocationTypeList : any =[];
  algorithmTypeList : any = [];
  TeamTypeList : any =[];
  teamList : any = [];
  staffList : any = [];
  ifConfigAdd:boolean=false;
  configForm: FormGroup;

  configDataForGrid :any = { content: [] };

  configGridSettings: any = {};

  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  filteredData = [];
  submitted:boolean=false;
  saveBtn:boolean=false;
  saveConfigBtn:boolean=false;
  isdisabled:boolean=false;
  //bucketType = [];
  caseReasonType = [];
  UtrUnitArray:any[]=[
    {
      text:'Hours',
      value:'Hours',
      
    },
    {
      text:'Days',
      value:'Days',
    }
  ];

  constructor(
    private CaseReason: CaseReasonService,
    public CommonStatus: CommonStatusService,
    public CommonTat: TatConsiderationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    public caseReasong: CaseReasonService,
    private route : ActivatedRoute,
    private cdRef:ChangeDetectorRef,
    private loadingService: LoadingService,
    public dialog:MatDialog,
    public dialogRef: MatDialogRef<CaseReasonAddUpdateComponent>,
    private sharingdataService: DataSharingServiceService,
    private commonListService: CommonListServicesService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
    
    ) {
   // super(toastrService, router, loadingService);
  }

  setGridSetting() {
    let self = this;
    this.allColumns = [
      { colKey: 'index', default: 'ID', rowKey: 'id', width: '5%', isFilterAllow: false, isNumber: true },
      {
        colKey: 'serviceAreaNameList',
        default: 'Service Area Name',
        rowKey: 'serviceAreaNameList',
        width: '10%',
        customControls: [],
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        isFilterAllow: false,
        enableSort: true,
      },
      {
        colKey: 'allocationType',
        default: 'Allocation Type',
        rowKey: 'allocationType',
        width: '10%',
        customControls: [],
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        isFilterAllow: false,
        enableSort: true,
      },
      {
        colKey: 'algorithmType',
        default: 'Algorithm Type',
        rowKey: 'algorithmType',
        width: '10%',
        customControls: [],
        isNumber: false,
        enableEdit: true,
        enableDelete: true,
        isFilterAllow: false,
        enableSort: true,
      },
      { 
        colKey: 'teamName',
        default: 'Team Name',
        rowKey: 'teamName',
        width: '10%',
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
      {
        colKey: 'teamType',
        default: 'Team Type',
        rowKey: 'teamType',
        width: '10%',
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
      { 
        colKey: 'staffUserName',
        default: 'Staff User',
        rowKey: 'staffUserName',
        width: '10%',
        isFilterAllow: false,
        enableEdit: true,
        enableDelete: true,
        enableSort: true
      },
       {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        //enableEdit: true,
        // enableDelete: true,
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '5%',
        isStickyEnd: true
      }
    ];

    this.configGridSettings = {
      
      showPagination: true,
      tableId: this.tableKeyEnum.CASE_REASON_CONFIG,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this config ?',
      deleteMsgBoxDetail: 'Config Details',
      
    };
  }

  ngOnInit() {
   // this.init();
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.commonStatus = res.dataList
    })
    this.CommonTat.getCommonListTatConsideration().subscribe((res) => {
      this.commonTat = res.dataList
    })

    // this.commonListService.getCommonListbucketType().subscribe((res) => {
    //   this.bucketType = res.dataList
    // });

    this.commonListService.getGenericCommonList('caseReasonType').subscribe((res) => {
      this.caseReasonType = res.dataList
    });

    this.form = new FormGroup({
      caseReason_name: new FormControl(null, [Validators.required]),
      caseReasonType: new FormControl('',[Validators.required]),
      caseReason_status: new FormControl(null, [Validators.required]),
      caseReason_tat: new FormControl(null, [Validators.required]),
      allowcustomer:new FormControl(false),
      etr:new FormControl(null),
      etrUnit:new FormControl(null),
      allowCommunication: new FormControl(false),
      //bucket: new FormControl(null)
    });
    this.configForm = new FormGroup({
      serviceAreaIdList: new FormControl(null, [Validators.required]),
      reasonid: new FormControl(null, [Validators.required]),
      teamType: new FormControl(null,[Validators.required]),
      allocationType: new FormControl(null,[Validators.required]),
      algorithmType: new FormControl(null,[Validators.required]),
      teamId: new FormControl(null,[Validators.required]),
      staffid: new FormControl(null,[Validators.required])
    });

    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.entity_id = Number(id);
      this.show_update = true;
      this.getCaseReasonById();
      this.isdisabled=false;
    }
    // if(this.data  && this.data.id){
    //   this.isdisabled=true;
    //   this.entity_id = this.data.id;
    //   this.show_update = true;
    //   /**Check AlterNate Condition */
    //    //this.ifConfigAdd=true;
      
    //   // this.ifConfigAdd=true;
    //   this.getCaseReasonById();
    //   this.form.disable();
    // }

    // this.sub = this.route
    // .queryParams
    // .subscribe(params => {
    //   let id = params['id']
    //   if (id) {
    //     this.entity_id = Number(params['id']);
    //     this.show_update = true;
    //     this.getCaseReasonById();
    //   }
    // });

    if  (this.show_update === true) {
      this.getAllCaseReasonConfigByCaseReasonId();
    }
    this.entity.etrUnit="Hours"
  }

  // resetStaff(){
  //   this.getAllStaff();
  // }
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  // onRoleChange(event){
  //   this.staffUserList = [];
  //   if(event){
  //     this.CaseReason.getStaffByRole(event.id).subscribe((res) => {
  //       this.staffUserList = res.staffUserlist
  //     })
  //   }
  //   else{
  //     this.staffUserList = []
  //   }

  // }

  
  onAddConfigClick(){
    this.configForm.reset();
    this.ifConfigAdd = true;
    this.serviceAreaList = [];
    this.TeamTypeList = null;
    this.allocationTypeList = null;
    this.configForm.get('reasonid').setValue(this.entity_id);
    this.CaseReason.getAllServiceArea().subscribe((res) => {
      this.serviceAreaList = res.dataList;
      // if(this.caseReasonConfigList !== undefined && this.caseReasonConfigList !== null && this.caseReasonConfigList.length > 0){
      //   let yFilter = this.caseReasonConfigList.map(item => { return item.serviceareaid; });
      //   this.serviceAreaList = tempArray.filter(item => !yFilter.includes(item.id));
      // }else{
      //   this.serviceAreaList = res.dataList;
      // }
    })
  }

  onChngServcArea(event){
    this.TeamTypeList = null;
    this.allocationTypeList = null;
    this.configForm.get('teamType').reset();
    this.configForm.get('teamType').updateValueAndValidity();
    this.configForm.get('allocationType').reset();
    this.configForm.get('allocationType').updateValueAndValidity();
    // if(event != undefined){
    //   this.serviceAreaId = event.id;
    //   this.CaseReason.getTeamType(this.serviceAreaId).subscribe((res) => {
    //     this.TeamTypeList = res.dataList
     
        
    //   })
    // }
    if(event){
      //this.serviceAreaId = event.id;
      this.CaseReason.getTeamTypeByServiceAreaForReason(this.configForm.get('serviceAreaIdList').value).subscribe((res) => {
        this.TeamTypeList = res.dataList
      })
    }
  }

  onChangeAllocType(event){
    this.configForm.get('algorithmType').reset();
    this.configForm.get('algorithmType').updateValueAndValidity();
    this.configForm.get('teamId').reset();
    this.configForm.get('teamId').updateValueAndValidity();
    this.configForm.get('staffid').reset();
    this.configForm.get('staffid').updateValueAndValidity();
    if(event.value == 'Auto'){
      this.configForm.get('teamId').clearValidators();
      this.configForm.get('teamId').updateValueAndValidity();
      this.configForm.get('staffid').clearValidators();
      this.configForm.get('staffid').updateValueAndValidity();
      this.configForm.get('algorithmType').setValidators(Validators.required)
      this.configForm.get('algorithmType').updateValueAndValidity();
      this.getAlgorithmType();
    }
    else{
      this.configForm.get('teamId').setValidators(Validators.required)
      this.configForm.get('teamId').updateValueAndValidity();
      this.configForm.get('staffid').setValidators(Validators.required)
      this.configForm.get('staffid').updateValueAndValidity();
      this.configForm.get('algorithmType').clearValidators();
      this.configForm.get('algorithmType').updateValueAndValidity();
      this.getTeamList1();
    }
  }

  getAllocationType(){
    this.commonListService.getGenericCommonList('allocationType').subscribe((res) => {
      this.allocationTypeList = res.dataList
    })
  }

  getAlgorithmType(){
    this.commonListService.getGenericCommonList('algorithmType').subscribe((res) => {
      this.algorithmTypeList = res.dataList;
    })
  }

  getTeamList1(){
    let obj = {
      serviceAreaIdList : [],
      teamType : this.teamType
    }
    obj.serviceAreaIdList = Object.assign([],this.configForm.get('serviceAreaIdList').value)
    this.CaseReason.getTeamList1(obj).subscribe((res)=>{
      this.teamList = res.dataList;
    })
  }

  onChangeTeam(event){
    this.staffList = null;
    this.configForm.get('staffid').reset();
    this.configForm.get('staffid').updateValueAndValidity();
    if(event != undefined){
      this.teamId = event.id
      for(let i = 0; i<this.teamList.length;i++){
        if(this.teamList[i].id == this.teamId){
          this.staffList = this.teamList[i].teamUserMapping.filter((ls) => ls.isAssignable);
        }
      }
    }
  }

  onChangeTeamType(event){
    this.allocationTypeList = null;
    this.configForm.get('allocationType').reset();
    this.configForm.get('allocationType').updateValueAndValidity();
    if(event != undefined){
      this.teamType = event.name;
      this.getAllocationType();
    }
  }

  getService(): IBaseService<CaseReasonModel> {
    return this.CaseReason;
  }

  setData(data: CaseReasonModel) {
    this.entity = data;
    //////console.log(this.entity);
  }

  getData(): CaseReasonModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {

    this.url = '/customer-care/caseReason'
    return this.url;
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): CaseReasonModel {
    return new CaseReasonModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }

  public getId(): number {
    return this.entity.reasonId;

  }

  get f() {
    return this.form.controls;
  }

  get cf() {
    return this.configForm.controls;
  }

  setValidation() {
    this.form = new FormGroup({
      caseReason_name: new FormControl('', [Validators.required]),
      caseReason_status: new FormControl('', [Validators.required]),
      caseReason_tat: new FormControl('', [Validators.required]),
      allowcustomer:new FormControl(false),
      allowCommunication: new FormControl(false),
    });
    ////console.log(this.form);
  }

  setValue(e){
    if(e.checked){
      this.form.get('allowcustomer').setValue(true);
      this.entity.isCustomerAllowed = true;
    }else{
      this.form.get('allowcustomer').setValue(false);
      this.entity.isCustomerAllowed = false;
    }
    //////console.log(this.entity.isCustomerAllowed)
  }

  setValueCommunication(e){
    if(e.checked){
      this.form.get('allowCommunication').setValue(true);
      this.entity.isCommunicationAllowed = true;
    }else{
      this.form.get('allowCommunication').setValue(false);
      this.entity.isCommunicationAllowed = false;
    }
    //////console.log(this.entity.isCustomerAllowed)
  }

  configFormSubmit(){
    ////console.log(this.configForm)
    if (!this.configForm.valid) {
      this.configForm.get('reasonid').setValue(this.entity_id);
      this.toastrService.error('Please fill all the details!', 'Error');
      Object.keys(this.configForm.controls).forEach(field => {
        const control = this.configForm.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        }
      });
    } else {
      this.configForm.get('teamType').setValue(this.teamType);
        this.insertCaseReasonConfigOnDb();
    }
  }

  insertCaseReasonConfigOnDb(){
    if (this.configForm.valid) {
      
      this.saveConfigBtn =true;
      this.CaseReason.insertCaseReasonConfig(this.configForm.value).subscribe(result => {
        ////console.log(result)
       if(result.responseCode === ResponseCode.OK){
        this.saveConfigBtn =false;
        this.toastrService.success('Case reason config has been created successfully', 'Success');
          //this.configForm.reset();
          this.configForm.reset();
          this.entity_id = result.data.reasonid;
          this.ifConfigAdd = false;
          this.getCaseReasonById();
          this.getAllCaseReasonConfigByCaseReasonId();
         // this.cdRef.detectChanges();
       } else{
        this.saveConfigBtn =false;
         this.toastrService.error(result.responseMessage)
       }
       },err => {
          this.saveConfigBtn =false;
          this.toastrService.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  caseReasonConfigList : any=[];
  getAllCaseReasonConfigByCaseReasonId() {
    this.setGridSetting();
    let caseReasonId = this.entity_id;
    this.CaseReason.getAllCaseReasonConfigByCaseReasonId(caseReasonId).subscribe(
      result => {
        //this.setGridSetting();
        this.caseReasonConfigList = result.dataList;
        this.configDataForGrid.content = result.dataList;
        this.refreshDataSource(this.configDataForGrid.content);
        this.cdRef.detectChanges();
        this.ngAfterViewInit();
      }
    );
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
    this.dataSource.connect().subscribe((data) => {
      this.filteredData = data;
    });
  }

  formSubmit() {
    
    this.submitted=true;
      if(this.form.invalid){
        return
      }
    if (!this.form.valid) {
      
      this.toastrService.error("Please fill all the details!");
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        }
      });
      } else {
        this.submitted=false
        //alertify.confirm("Case-reason", "Are you sure you want to save this record?");
        if (this.entity_id === null) {
          this.insertCaseReasonOnDb();
        } else {
          this.updateCaseReasonOnDb();
        }
    }
  }

  getErrorMsg(controlName: string) {
    const formGroup = this.getFormGroup();
    const formControl = formGroup.controls[controlName];
    if (formControl) {
      if (formControl.errors && formControl.errors.required) {
        return 'This field is required.';
      } else if (formControl.errors && formControl.errors.minlength) {
        return `Please enter atleast string with ${formControl.errors.minlength.requiredLength} characters.`;
      } else if (formControl.errors && formControl.errors.maxlength) {
        return `Please enter at max string with ${formControl.errors.maxlength.requiredLength} characters.`;
      } else if (formControl.errors && formControl.errors.min) {
        return `Please enter minimum ${formControl.errors.min.min} as value.`;
      } else if (formControl.errors && formControl.errors.max) {
        return `Please enter value upto ${formControl.errors.max.max}.`;
      }
      return '';
    }
    return '';
  }

  getCaseReasonById() {
    this.show_update = true;
    let entity_id = this.entity_id;
    this.CaseReason.getcaseReasonByID(entity_id).subscribe(
      result => {
        this.entity = result.data;
        //////console.log(this.entity.isCustomerAllowed);
        this.configForm.get('reasonid').setValue(result.data.reasonId);
        this.setUpdateValueInForm(this.entity);
        //this.ifConfigAdd = true;
        //this.onAddConfigClick();
      }
    )
  }

  setUpdateValueInForm(data) {
    
    this.show_update = true;
    this.selectForUpdate = data;
    this.form.patchValue(data);
  }

  insertCaseReasonOnDb() {
    if (this.form.valid) {
      this.saveBtn =true;
      let formatedInputData:any={};
      formatedInputData.name = this.form.get('caseReason_name').value.trim();
      formatedInputData.status = this.form.get('caseReason_status').value;
      formatedInputData.caseReasonType=this.form.get('caseReasonType').value;
      formatedInputData.etr=this.form.get('etr').value;
      formatedInputData.etrUnit=this.form.get('etrUnit').value;
      formatedInputData.tatConsideration= this.form.get('caseReason_tat').value;
      formatedInputData.isCustomerAllowed = this.form.get('allowcustomer').value;
      formatedInputData.isCommunicationAllowed = this.form.get('allowCommunication').value;
      //formatedInputData.bucket = this.form.get('bucket').value;
      this.CaseReason.insertCaseReason(formatedInputData).subscribe(result => {
        if(result.responseCode === ResponseCode.OK){
          this.saveBtn =false;
          this.toastrService.success('Case reason has been created successfully');
          this.configForm.get('reasonid').setValue(result.data.reasonId);
          this.entity_id = result.data.reasonId;
          this.ifConfigAdd = false;
          this.show_update = true;
          this.getCaseReasonById();
          this.getAllCaseReasonConfigByCaseReasonId();
          //this.onAddConfigClick();
          //this.backToViewList();
        }
        else{
          this.toastrService.error(result.responseMessage)        }
        },
        err => {
          this.saveBtn =false;
          this.toastrService.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  updateCaseReasonOnDb() {
    if (this.form.valid) {
      this.saveBtn =true;
      let formatedInputData:any={};
      formatedInputData.reasonId = this.entity_id;
      formatedInputData.name = this.form.get('caseReason_name').value.trim();
      formatedInputData.status = this.form.get('caseReason_status').value;
      formatedInputData.caseReasonType=this.form.get('caseReasonType').value
      formatedInputData.etr=this.form.get('etr').value
      formatedInputData.etrUnit=this.form.get('etrUnit').value
      formatedInputData.tatConsideration= this.form.get('caseReason_tat').value;
      formatedInputData.isCustomerAllowed = this.form.get('allowcustomer').value;
      formatedInputData.isCommunicationAllowed = this.form.get('allowCommunication').value;
      formatedInputData.caseReasonConfigList = this.configDataForGrid.content;
      //formatedInputData.bucket = this.form.get('bucket').value;
      this.CaseReason.updateCaseReason(formatedInputData).subscribe(result => {
        if(result.responseCode === ResponseCode.OK){
          this.saveBtn =false;
          this.toastrService.success('Case reason has been update successfully');
          this.backToViewList();
        }
      else{
        this.saveBtn =false;
        this.toastrService.error(result.responseMessage) 
      }
        },
        err => {
          this.saveBtn =false;
          this.toastrService.error(err.error.status + " " + err.error.ERROR);
        });
    }
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      // if(col.key === 'algorithmType') {
      //   col.customControls = [this.algoTypeRef];
      // }
    });
  }

  onDelete(event) {
    let self = this;
    if (event) {
      self.delete(event);
    }
 }

 delete(data) {
  let self = this;
  this.CaseReason.deleteCaseReasonConfig(data).subscribe((res : any) => {
    if(res.responseCode === ResponseCode.OK){
      this.toastrService.success('Case reason config is deleted successfully');
      this.entity_id = res.data.reasonid;
      // this.getCaseReasonById();
      // this.getAllCaseReasonConfigByCaseReasonId();
      //this.show_update = true;
      //this.ifConfigAdd = false;
      self.configDataForGrid.content.forEach(function (item, index) {
        if (item.id === data.id) {
          self.configDataForGrid.content.splice(index, 1);
        }
      });
      self.refreshDataSource(self.configDataForGrid.content);
    }
  },err => {
    this.toastrService.error(err.error.status + " " + err.error.ERROR);
  });
  }

  backToconfigList(){
    this.ifConfigAdd = false;
    this.getAllCaseReasonConfigByCaseReasonId();
  }

  
  backToViewList() {
    //this.router.navigate(['/customer-care/caseReason']);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
    }else{
      this.router.navigate(['/customer-care/caseReason']);
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
